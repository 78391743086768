<template>
  <div :class="{ 'position-relative': icon }">
    <!-- Textarea -->
    <textarea
      v-if="type.toLowerCase() === 'textarea'"
      ref="input"
      v-model="newValue"
      :rows="rows"
      v-bind="$attrs"
      class="form-control"
      :class="[{ error: error }, classes]"
      :disabled="disabled"
      v-on="listeners"
    >
    </textarea>
    <!-- Checkbox -->
    <div v-else-if="type.toLowerCase() === 'checkbox'" class="align-middle">
      <label :for="$attrs.name">
        <input
          :id="$attrs.name"
          ref="input"
          v-model="newValue"
          :type="type"
          v-bind="$attrs"
          class="form-check-input me-2"
          :class="[classes]"
          style="vertical-align: text-top"
          :disabled="disabled"
          v-on="listeners"
        />
        <slot />
      </label>
    </div>
    <div v-else-if="type.toLowerCase() === 'reversedcheckbox'" class="align-middle">
      <label :for="$attrs.name">
        <slot />
        <input
          :id="$attrs.name"
          ref="input"
          v-model="newValue"
          :type="'checkbox'"
          v-bind="$attrs"
          class="form-check-input ms-2"
          :class="[classes]"
          style="vertical-align: text-top"
          :disabled="disabled"
          v-on="listeners"
        />
      </label>
    </div>

    <!-- Text Input -->
    <input
      v-else
      ref="input"
      v-model="newValue"
      :placeholder="placeholder"
      :type="type"
      v-bind="$attrs"
      class="form-control"
      :class="[{ error: error }, { withIcon: icon }, classes]"
      :disabled="disabled"
      v-on="listeners"
    />
    <BaseIcon v-if="icon" :name="icon" class="icon form-icon" :classes="iconClasses" />

    <!-- Error text -->
    <p v-if="error" class="text-warning fw-light">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    // value getter
    value() {
      return this.$data.newValue;
    },
    // name getter
    name() {
      return this.$attrs.name;
    },
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    icon: {
      type: [String, Array],
      default() {
        return undefined;
      },
    },
    iconClasses: {
      type: String,
      default() {
        return undefined;
      },
    },
    rows: {
      type: Number,
      default: 5,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: undefined,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit(
            "input",
            event.target.type === "checkbox" ? event.target.checked === true || false : event.target.value
          );
          this.$root.$emit("inputValueChange", {
            name: this.$attrs.name,
            value: event.target.type === "checkbox" ? event.target.checked === true || false : event.target.value,
          });
        },
        blur: (event) => (event.target.type !== "checkbox" ? this.$emit("blur", event.target.value) : false),
        change: (event) => (event.target.type !== "checkbox" ? this.$emit("blur", event.target.value) : false),
        focus: (event) => (event.target.type !== "checkbox" ? this.$emit("blur", event.target.value) : false),
      };
    },
    // True if vee-validate has any error for this field's name
    error() {
      return this.$attrs.error;
    },
  },
  watch: {
    value() {
      this.newValue = this.value;
    },
  },
  mounted() {
    this.newValue = this.value;
    this.$root.$emit("inputValueChange", { name: this.$attrs.name, value: this.newValue });
  },
};
</script>
