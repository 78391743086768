<template>
  <div>
    <Multiselect
      ref="multiselect"
      v-model="currentValue"
      rel="select"
      :options="options"
      :placeholder="placeholder"
      :multiple="multiple"
      :show-labels="false"
      :allow-empty="allowEmpty"
      :searchable="true"
      :track-by="trackBy"
      :label="label"
      v-bind="$attrs"
      :class="{ error: error }"
      v-on="listeners"
    >
      <!-- Right side caret -->
      <!-- <span slot="caret" slot-scope="{ toggle }" class="multiselect__arrow" @mousedown.prevent.stop="toggle" /> -->
      <span slot="noResult">
        {{ "No matches found, please try again." }}
      </span>
      <span slot="noOptions">
        {{ "No matches found, please try again." }}
      </span>
      <!-- <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.title }}</span
          ><span class="option__small">{{ props.option.desc }}</span>
        </div>
      </template> -->
      <template #tag="slotProps">
        <span
          class="multiselect__tag"
          :style="{
            'background-color': slotProps.option.color ? slotProps.option.color : defaultColor,
            color: getContrastingColour(slotProps.option.color ? slotProps.option.color : defaultColor),
          }"
        >
          <span v-text="slotProps.option.label"></span>
          <i tabindex="1" class="multiselect__tag-icon" @click="slotProps.remove(slotProps.option)"></i>
        </span>
      </template>
      <template #singleLabel="slotProps">
        <span
          v-if="!defaultSingleView"
          class="multiselect__tag"
          :style="{
            'background-color': slotProps.option.color ? slotProps.option.color : defaultColor,
            color: getContrastingColour(slotProps.option.color ? slotProps.option.color : defaultColor),
          }"
        >
          <span v-text="slotProps.option.label"></span>
        </span>
        <span v-else>{{ slotProps.option.label }}</span>
      </template>
    </Multiselect>

    <!-- Error text -->
    <p v-if="error" class="errorText">
      {{ error }}
    </p>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import contrastingColour from "@utils/color";

export default {
  $_veeValidate: {
    // value getter
    value() {
      return this.$rel.select.value;
    },
    // name getter
    name() {
      return this.$attrs.name;
    },
  },
  name: "BaseMultiselect",
  components: {
    Multiselect,
  },
  props: {
    placeholder: {
      type: String,
      default: "Begin typing or use dropdown",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "label",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    defaultSingleView: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [
        {
          id: 0,
          label: "Not applicable",
        },
      ],
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    validate: {
      type: [String, Array, Object],
      default: null,
    },
  },
  data() {
    return {
      currentValue: "",
      defaultColor: "#2aaaed",
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event),
        blur: (event) => this.$emit("blur", event),
        change: (event) => this.$emit("change", event),
        focus: (event) => this.$emit("focus", event),
      };
    },
    // True if vee-validate has any error for this field's name
    error() {
      return this.$attrs.error;
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  mounted() {
    this.currentValue = this.value;
  },
  methods: {
    getContrastingColour(color) {
      return contrastingColour(color);
    },
  },
};
</script>
