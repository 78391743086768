// Defaults and resovlers for local store
import gql from 'graphql-tag'

export const typeDefs = gql`
  type Query {
    user: User
  }
`

export const initialState = {
  user: null
}

export const resolvers = {
  Mutation: {
    // Save current logged user in Local apollo state
    setSelf: (parent, { user }, { cache }) => {
      user.isTeamUser = (user.role.name === 'TEAM' || user.role.name === 'ADMIN' || user.role.name === 'SYSADMIN')
      user.isAdminUser = (user.role.name === 'ADMIN' || user.role.name === 'SYSADMIN')
      user.isViewer = (user.role.name === 'VIEWER')
      
      cache.writeData({
        data: {
          user: user ? { ...user, __typename: 'User' } : null,
        },
      })
      return null
    },
  },
}