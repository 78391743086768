<template>
  <div class="form-group row mt-2">
    <label
      :class="{ 'col-4': size === 'md', 'col-2': size === 'lg', 'col-6': size === 'sm' }"
      class="pt-2 col-form-label text-primary-dark fw-bolder align-middle"
    >
      {{ label }}
    </label>
    <div :class="{ 'col-8': size === 'md', 'col-10': size === 'lg', 'col-6': size === 'sm' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>
