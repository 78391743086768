<template>
  <Layout class='text-center'>
    <h1 class='display-3 mb-3 d-block'>403 – you can't access this</h1>
    <p>
      Your account does not have permission to access this <br />
      If you believe it should, please contact the admin with this link:
    </p>
    <pre v-if="resource" class='mt-4 p-4 bg-gray d-inline-block'>{{ resource }}</pre>
    <pre v-else  class='mt-4 p-4 bg-gray d-inline-block' >{{ redirectedFrom }}</pre>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  page: {
    title: '403',
    meta: [{ name: 'description', content: '403' }],
  },
  components: { Layout },
  /**
   * This assigns the previous page to the DATA object in our component so we can
   * display it to the user
   *
   * https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
   */
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.redirectedFrom = to.redirectedFrom || 'direct access'
    })
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      redirectedFrom: '/'
    }
  },
}
</script>
