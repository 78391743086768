<template>
  <div>
    <NavBar />

    <div class="mt-3 px-3">
      <slot />
    </div>

    <footer class="container-fluid my-4">
      <hr class="dotted-spacer" />
      <span class="small text-gray-400">
        <a href="https://outcomms.io" class="text-gray-400 text-decoration-none">outcomms</a>
        | &copy; 2021
        <a href="https://www.jadepacific.eu" class="text-gray-400 text-decoration-none">Jade Pacific</a>
        | All content strictly private and confidential.
      </span>
    </footer>
  </div>
</template>

<script>
import NavBar from "@components/nav-bar";

export default {
  components: { NavBar },
};
</script>
