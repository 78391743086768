<template>
  <FontAwesomeIcon v-if="source === 'font-awesome'" :icon="name" v-bind="$attrs" :class="classes" />
  <span v-else-if="source === 'custom'" v-bind="$attrs" :class="customIconClass + ' ' + classes" />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library as fontAwesomeIconLibrary } from "@fortawesome/fontawesome-svg-core";

import camelCase from "lodash/camelCase";

const icons = [
  require("@fortawesome/free-solid-svg-icons/faTimes"),
  require("@fortawesome/free-solid-svg-icons/faTimesCircle"),
  require("@fortawesome/free-solid-svg-icons/faCheck"),
  require("@fortawesome/free-solid-svg-icons/faPlus"),
  require("@fortawesome/free-solid-svg-icons/faMinus"),
  require("@fortawesome/free-solid-svg-icons/faExclamationTriangle"),
  require("@fortawesome/free-solid-svg-icons/faCheckCircle"),

  require("@fortawesome/free-solid-svg-icons/faSearch"),
  require("@fortawesome/free-solid-svg-icons/faPrint"),

  require("@fortawesome/free-solid-svg-icons/faChartPie"),
  require("@fortawesome/free-solid-svg-icons/faLightbulb"),
  require("@fortawesome/free-solid-svg-icons/faSync"),
  require("@fortawesome/free-solid-svg-icons/faSpinner"),
  require("@fortawesome/free-solid-svg-icons/faUser"),

  require("@fortawesome/free-regular-svg-icons/faTimesCircle"),
  require("@fortawesome/free-regular-svg-icons/faCheckCircle"),
  require("@fortawesome/free-regular-svg-icons/faEdit"),
  require("@fortawesome/free-solid-svg-icons/faAngleDown"),

  require("@fortawesome/free-solid-svg-icons/faInfoCircle"),

  require("@fortawesome/free-regular-svg-icons/faLightbulb"),
  require("@fortawesome/free-solid-svg-icons/faStream"),
  require("@fortawesome/free-solid-svg-icons/faUndo"),
  require("@fortawesome/free-regular-svg-icons/faTrashAlt"),
  require("@fortawesome/free-regular-svg-icons/faEyeSlash"),
  require("@fortawesome/free-regular-svg-icons/faEye"),

  require("@fortawesome/free-solid-svg-icons/faChevronLeft"),
  require("@fortawesome/free-solid-svg-icons/faChevronRight"),
];

fontAwesomeIconLibrary.add(icons.map((icon) => icon.definition));

export default {
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: "font-awesome",
    },
    name: {
      type: [Array, String],
      required: true,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase("icon-custom-" + this.name)];
    },
  },
};
</script>
