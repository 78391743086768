<template>
  <img :style="style" class="rotate" :src="spinner" name="sync" alt="loading" />
</template>
<script>
import spinner from "@assets/images/outcommsicon.png";
export default {
  data() {
    return {
      spinner: spinner,
      style: "max-height: 10vh",
    };
  },
};
</script>
<style scoped>
.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
