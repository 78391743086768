// Polyfills
import "core-js";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./app";
import router from "@router";
import { apolloProvider } from "@state";
import "@components/globals";

import VueLogger from "vuejs-logger";
import { extend, setInteractionMode } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import VueGoodTablePlugin from "vue-good-table";
import VueSlideoutPanel from "vue2-slideout-panel";
import VTooltip from 'v-tooltip'

// import the styles
import "vue-good-table/dist/vue-good-table.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Don't warn about using the dev version of Vue in development
const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = isProduction;

// If running inside Cypress...
if (window.Cypress) {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;
}

Vue.prototype.$log = (...args) => console.log(...args); // eslint-disable-line no-console

// Set up VueLogger ($this.log.warn...)
const VueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, VueLoggerOptions);

Vue.use(VTooltip)

// Vee-Validate settings

extend("required", {
  ...required,
  message: "'{_field_}' is required.",
});
extend("email", { ...email });

setInteractionMode("eager");

Vue.use(VueGoodTablePlugin);

Vue.use(VueSlideoutPanel);

const app = new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount("#app");

// If running inside Cypress...
if (window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`
  window.__app__ = app;
}
