<template>
  <div id="app">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <RouterView :key="$route.fullPath" />
  </div>
</template>

<script>
import appConfig from '@src/app.config'


export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      // title = typeof title === 'function' ? title(this.$store) : title // TODO
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
}
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.
//  This is currently a bootstrap theme
@import '@design';


// ===
// Vendor
// ===

#nprogress .bar {
  background: $link-color;
}
</style>
