<template>
  <div role="alert" class="mx-3" :class="type">
    <div class="p-3">
      <div class="text-muted">
        <BaseIcon v-if="type === 'error'" :name="['fas', 'exclamation-triangle']" />
        <BaseIcon v-if="type === 'success'" :name="['fas', 'info-circle']" />
      </div>
      <div>
        <div class="h5 px-4" :class="{ 'text-warning': type === 'error', 'text-success': type === 'success' }">
          <slot name="title">Error</slot>
        </div>
        <div class="lead px-4 pb-3">
          <slot name="message">There is an error</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      type: String,
      default: "error",
    },
  },
  mounted() {},
};
</script>
