<template>
  <div>
    <DateRangePicker
      v-if="datepicker.rangeDate"
      ref="picker"
      v-model="datepicker.rangeDate"
      class="DateRangePicker w-100"
      :start-date="datepicker.startDate"
      :end-date="datepicker.endDate"
      :locale-data="datepicker.locale"
      :ranges="datepicker.ranges"
      :single-date-picker="datepicker.singleDatePicker"
      :opens="datepicker.opens || 'center'"
      @update="updateValue"
    >
      <div slot="input" class="w-100">
        <span v-if="datepicker.timeline">
          {{ displayValue }}
        </span>
        <span v-else-if="datepicker.singleDatePicker">
          {{ formattedStart }}
        </span>
        <span v-else>
          {{ formattedStart }} -
          {{ formattedEnd }}
        </span>
      </div>
    </DateRangePicker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { format, parseISO } from "date-fns";

export default {
  name: "BaseDatepicker",
  components: { DateRangePicker },
  props: {
    // Options is optional. Will be merged with any defaultDatepicker
    options: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
    displayValue: {
      type: String,
      default: "go to date",
    },
  },
  data() {
    return {
      defaultDatepicker: {
        format: "dd MMM yyyy",
        minDate: "2000-09-05",
        maxDate: "2100-01-01",
        ranges: false, // removes ranges UI from date popup
        locale: { firstDay: 1, format: "DD/MM/YYYY" },
        rangeDate: {
          startDate: format(new Date(), "yyyy-MM-dd"),
          endDate: format(new Date(), "yyyy-MM-dd"),
        },
      },
      datepicker: {
        format: "dd MMM yyyy",
        rangeDate: {
          startDate: format(new Date(), "yyyy-MM-dd"),
          endDate: format(new Date(), "yyyy-MM-dd"),
        },
      },
    };
  },
  computed: {
    formattedStart() {
      return format(parseISO(this.datepicker.rangeDate.startDate), this.datepicker.format);
    },
    formattedEnd() {
      return format(parseISO(this.datepicker.rangeDate.endDate), this.datepicker.format);
    },
  },
  watch: {
    value() {
      this.defaultDatepicker.rangeDate = this.value;
    },
  },
  mounted() {
    if (this.value && this.value.startDate) {
      this.$log.debug("BaseDatepicker - got values from parent", this.value);
      this.defaultDatepicker.rangeDate = this.value;
    }

    // Options prop is used to override any default styles
    this.datepicker = {
      ...this.defaultDatepicker,
      ...this.options,
    };
  },
  methods: {
    updateValue(value) {
      // On Update format the date ignoring the timezone
      const oldValue = value;
      value.startDate = format(new Date(value.startDate), "yyyy-MM-dd");
      value.endDate = format(new Date(value.endDate), "yyyy-MM-dd");

      this.$log.info("BaseDatepicker - updateValue", { unformatted: oldValue, formatted: value });
      this.$emit("input", value);
    },
  },
};
</script>
