import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import routes from './routes'
import { getCurrentUser, checkSession } from '@services/auth'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }
  
  // Check if session is valid and renew if necessary.
  // Fetch user asynchronously after checking session.
  const userPromise = checkSession().then(getCurrentUser) 
  
  // Let user access permissionless pages without an account
  if (['login', 'logout', '404', '403'].includes(routeTo.name)) return next()
  
  // If auth isn't required for the route (or nested routes),
  // just continue and don't wait for user object.
  if (!routeTo.matched.some(route => !route.meta.permissions.public)) return next()
  
  // Wait for user object to check permissions
  const user = await userPromise
  Vue.$log.debug("User Resolved", user)

  // Handle permissions (except for sysadmin)
  if (!user) {
    next({ name: 'login'})
  } 
  else if (!user.isTeamUser && !user.isViewer) {
    // No Permissions => Can only see profile
    next({ name: 'user-profile', params: { id: user.id } })
  } 
  else if (routeTo.matched.some(route => !route.meta.permissions.viewer) === true && user.isViewer) {
    // If route doesn't contain viewer permission and the user is a viewer
    next({ name: '403', params: { resource: routeTo.matched[0] ? routeTo.matched[0].name : '-' } })
  } 
  else if (routeTo.matched.some(route => !route.meta.permissions.user) === true && user.isTeamUser && !user.isAdminUser) {
    // If route doesn't contain user permission and the user is a team user
    next({ name: '403', params: { resource: routeTo.matched[0] ? routeTo.matched[0].name : '-' } })
  } 
  else if (routeTo.matched.some(route => !route.meta.permissions.admin) === true && user.isAdminUser && (!user.role.name === 'SYSADMIN')) {
    // If route doesn't contain admin permission and the user is a admin
    next({ name: '403', params: { resource: routeTo.matched[0] ? routeTo.matched[0].name : '-' } })
  }
  
  // eslint-disable-next-line no-unreachable
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
