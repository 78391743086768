<template>
  <div
    ref="tooltip"
    class="tooltip fade show bs-tooltip-top"
    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px);"
  >
    <div class="tooltip-arrow" style="left:calc(50% - 6px)"></div>
    <div class="tooltip-inner"><slot></slot></div>
  </div>
  <!-- 
    <div class="tooltip fade show bs-tooltip-top" role="tooltip"> 
      <div class="tooltip-arrow" style="left:calc(50% - 6px)"></div> 
      <div class="tooltip-inner">Tooltip on the top</div> 
    </div>
  -->
  <!-- 
  <div class="tooltip fade show bs-tooltip-bottom" role="tooltip" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(944px, 1757px, 0px);" data-popper-placement="bottom">
    <div class="tooltip-arrow" style="position: absolute; left: 0px; transform: translate3d(59px, 0px, 0px);"></div>
    <div class="tooltip-inner">Tooltip on bottom</div>
  </div>
   -->
</template>

<script>
export default {
  name: "BaseTooltip",
  props: {},
  computed: {},
};
</script>