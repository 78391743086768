<template>
  <Layout>
    <Transition appear>
      <BaseIcon name="sync" spin />
    </Transition>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: { Layout },
}
</script>
