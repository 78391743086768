<template>
  <button class="btn" :class="classes" v-bind="$attrs" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
