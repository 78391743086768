<template>
  <div id="navbar">
    <nav class="navbar navbar-expand-sm px-3" style="background-color: #334659">
      <div class="d-flex flex-grow-1 align-items-center">
        <a class="navbar-brand ms-5" href="">
          <img :src="clientLogo" alt="logo" style="max-height: 40px" />
        </a>
        <span class="px-3">
          <a href="/" class="text-white h4" style="font-weight: 600"
            ><img :src="outcommsLogo" alt="outcomms" style="max-height: 40px"
          /></a>
        </span>
        <div class="w-100 text-end">
          <button class="navbar-toggler" type="button" @click="collapse()">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse flex-grow-1 text-end">
        <ul class="navbar-nav ms-auto flex-nowrap">
          <span class="navbar-text"> Hi {{ user ? user.name || user.email : '' }} </span>
          <li v-for="route in persistantNavLinks" :key="route.name" class="nav-item">
            <BaseLink :to="route">
              <a class="nav-link-top">{{ route.title }}</a>
            </BaseLink>
          </li>
          <li v-for="route in adminNavLinks" :key="route.name" class="nav-item">
            <BaseLink :to="route">
              <a class="nav-link-top">{{ route.title }}</a>
            </BaseLink>
          </li>
        </ul>

        <BaseLink :to="profileNavLink">
          <a class="nav-link-top">
            <img
              class="rounded-circle"
              :src="user ? user.displayPictureUrl || defaultDisplayPicture : defaultDisplayPicture"
              style="width: 40px"
            />
          </a>
        </BaseLink>
        <BaseLink :to="{ name: 'logout' }">
          <a class="btn btn-info" role="button">Logout</a>
        </BaseLink>
      </div>
    </nav>
    <nav v-if="user && user.isTeamUser" class="navbar navbar-light navbar-expand-sm tabbar-xero pb-0">
      <div class="nav nav-tabs" role="tablist">
        <BaseLink :to="{ name: 'home' }">
          <a
            class="nav-link font-display"
            :class="{ active: $route.name == 'home' }"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
            >Calendar</a
          >
        </BaseLink>
        <BaseLink :to="{ name: 'timeline' }">
          <a
            class="nav-link font-display"
            :class="{ active: $route.name == 'timeline' }"
            role="tab"
            aria-controls="nav-timeline"
            aria-selected="false"
            >Timeline</a
          >
        </BaseLink>
        <BaseLink :to="{ name: 'activities' }">
          <a
            class="nav-link font-display"
            :class="{ active: $route.name == 'activities' }"
            role="tab"
            aria-controls="nav-activities"
            aria-selected="false"
            >Activities <span v-if="activities && activities > 0" class="badge bg-danger">{{ activities }}</span></a
          >
        </BaseLink>
        <BaseLink :to="{ name: 'reminders' }">
          <a
            class="nav-link font-display"
            :class="{ active: $route.name == 'reminders' }"
            role="tab"
            aria-controls="nav-reminders"
            aria-selected="false"
            >Reminders</a
          >
        </BaseLink>
      </div>
    </nav>
  </div>
</template>

<script>
import { LocalGetSelf } from "@gql/user";
import { GetActivitiesAwaitingApprovalCount } from "@gql/activity";

import { format } from "date-fns";

import defaultDisplayPicture from "@assets/images/person-fill.png";
import clientLogo from "@assets/images/logo-xero.png";
import outcommsLogo from "@assets/images/outcommslogo.png";

export default {
  data() {
    return {
      persistantNavLinks: [
        {
          name: "home",
          title: "Calendar",
        },
      ],
      defaultDisplayPicture: defaultDisplayPicture,
      outcommsLogo: outcommsLogo,
      clientLogo: clientLogo,
      activitiesAlerts: 4,
    };
  },
  computed: {
    profileNavLink: function () {
      return { name: "user-profile", params: { id: this.user ? this.user.id : '' } };
    },
    adminNavLinks: function () {
      if (this.user && this.user.isAdminUser) {
        return [
          {
            name: "tags",
            title: "Tags",
          },
          {
            name: "users",
            title: "Users",
          },
        ];
      }
      return [];
    },
  },
  apollo: {
    user: {
      query: LocalGetSelf,
    },
    activities: {
      query: GetActivitiesAwaitingApprovalCount,
      variables() {
        return {
          companyId: this.user.company.id,
          startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        };
      },
      skip() {
        return !this.user;
      },
      update: function (data) {
        this.$log.debug("Got activities awaiting count from API: ", data);
        return data.activityCount;
      },
      error: function (err) {
        this.$log.error(err);
      },
      fetchPolicy: "network-only",
    },
  },
};
</script>
