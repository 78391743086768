import { tryToLogIn, logout } from "@services/auth";

/**
 * Permissions are handled on an implicitly denied basis:
 * That is unless a user explicitly has permissions, it is assumed denied.
 */
export default [
  {
    path: "/",
    name: "home",
    component: () => lazyLoadView(import("@views/home")),
    meta: {
      permissions: {
        public: false,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () => lazyLoadView(import("@views/timeline")),
    meta: {
      permissions: {
        public: false,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/users/",
    name: "users",
    component: () => lazyLoadView(import("@views/users/index.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/users/edit/:id",
    name: "users.edit",
    component: () => lazyLoadView(import("@views/users/edit.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/users/new",
    name: "users.new",
    component: () => lazyLoadView(import("@views/users/new.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/profile/:id",
    name: "user-profile",
    component: () => lazyLoadView(import("@views/profile")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/new",
    name: "briefs.new",
    component: () => lazyLoadView(import("@views/briefs/new.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/briefs/:id",
    name: "briefs.edit",
    component: () => lazyLoadView(import("@views/briefs/edit.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/activities",
    name: "activities",
    component: () => lazyLoadView(import("@views/activities/index.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/tags/",
    name: "tags",
    component: () => lazyLoadView(import("@views/tags/index.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/tags/edit/:id",
    name: "tags.edit",
    component: () => lazyLoadView(import("@views/tags/edit.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/tags/new",
    name: "tags.new",
    component: () => lazyLoadView(import("@views/tags/new.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/tags/types/edit/:id",
    name: "tags.types.edit",
    component: () => lazyLoadView(import("@src/router/views/tags/edit-type.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/reminders/",
    name: "reminders",
    component: () => lazyLoadView(import("@views/reminders/index.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/reminders/edit/:id",
    name: "reminders.edit",
    component: () => lazyLoadView(import("@views/reminders/edit.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/reminders/new",
    name: "reminders.new",
    component: () => lazyLoadView(import("@views/reminders/new.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  {
    path: "/groups/",
    name: "groups",
    component: () => lazyLoadView(import("@views/groups/index.vue")),
    props: true,
    meta: {
      permissions: {
        public: false,
        viewer: false,
        user: false,
        admin: true,
      },
    },
  },
  // {
  //   path: "/groups/edit/:id",
  //   name: "groups.edit",
  //   component: () => lazyLoadView(import("@views/groups/edit.vue")),
  //   props: true,
  //   meta: {
  //     permissions: {
  //       public: false,
  //       viewer: false,
  //       user: false,
  //       admin: true,
  //     },
  //   },
  // },
  // {
  //   path: "/groups/new",
  //   name: "groups.new",
  //   component: () => lazyLoadView(import("@views/groups/new.vue")),
  //   props: true,
  //   meta: {
  //     permissions: {
  //       public: false,
  //       viewer: false,
  //       user: false,
  //       admin: true,
  //     },
  //   },
  // },
  {
    path: "/login",
    name: "login",
    component: () => lazyLoadView(import("@views/login")),
    beforeEnter: async (routeTo, routeFrom, next) => {
      const loggedIn = await tryToLogIn();

      // Redirect to home page if the user is
      // logged in or continue otherwise
      loggedIn ? next({ name: "home" }) : next();
    },
    meta: {
      permissions: {
        public: true,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(routeTo, routeFrom, next) {
      // Continue before calling logout in order to avoid triggering
      // extra requests in the current page when cache is cleared.
      next();
      logout();
    },
    meta: {
      permissions: {
        public: true,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: () => lazyLoadView(import("@views/loading")),
    meta: {
      permissions: {
        public: true,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("@views/404").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
    meta: {
      permissions: {
        public: true,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  {
    path: "/403",
    name: "403",
    component: require("@views/403").default,
    // Allows props to be passed to the 403 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
    meta: {
      permissions: {
        public: true,
        viewer: true,
        user: true,
        admin: true,
      },
    },
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
];

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@views/loading").default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@views/timeout").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
